div.alphabet {
	position: relative;
	display: table;
	width: 100%;
	margin-bottom: 1em;
}

div.alphabet span {
	display: table-cell;
	color: #3174c7;
	cursor: pointer;
	text-align: center;
}

div.alphabet span:hover {
	text-decoration: underline;
}

div.alphabet span.active {
	color: black;
}

div.alphabet span.empty {
	color: red;
}

div.alphabetInfo {
	display: block;
	position: absolute;
	background-color: #111;
	border-radius: 3px;
	color: white;
	top: 2em;
	height: 1.8em;
	padding-top: 0.4em;
	text-align: center;
	z-index: 1;
}
